@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input {
  color-scheme: dark;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  color: rgba(255, 255, 255, 1);
}

.input-text-color{
  color: white!important;
  font-weight: 300;
}

.input-main{
  color: white!important;
  background: rgba(118, 118, 128, 0.24)!important;
}

.input-main:hover{
  color: white!important;
  opacity: 0.9!important;
  background: rgba(118, 118, 128, 0.24)!important;
}

.input-main:focus{
  color: white!important;
  opacity: 1.0!important;
  background: rgba(118, 118, 128, 0.24)!important;
}

.wrapper{
  background: #2A2A2D!important;
}

.wrapper-content{
  color:white!important;
  background: none!important;
}

.table-wrapper{
  height: calc(100vh - 250px)!important;
  overflow: auto!important;
  padding-bottom: 140px;
}

.table{
  background: transparent!important;
}

.base-wrapper{
  color: white!important;
  background: none!important;
  padding: 0!important;
}

.group[data-odd=true] .group-data-\[odd\=true\]\:before\:opacity-100::before {
  background: #222!important;
}

.tablist-back{
  background: #9E9EA93D;
  width: 400px;
}

.tablist-tab{
  background: #1C1717;
  border: 1px solid #BDFF00
}

.tablist-text{
  color: white;
}

